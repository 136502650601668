@import 'framapiaf/variables';
@import 'application';
@import 'mastodon-light/diff';
@import 'framapiaf/fixes';

body {
  background: #eee;

  div.dropdown-menu {
    display: block !important;
  }
}

.ui {
  background: #eee;
  padding-bottom: 0;

  .dropdown,
  .dropup {
    position: absolute;
  }
}

.column-header,
.column-header__button,
.column-header__back-button,
.column-back-button {
  background: #6a5687;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.column-header__button {
  &:hover {
    color: darken($ui-base-lighter-color, 10%);
  }
}

.column-header > .column-header__back-button {
  color: #fff;
}

.compose-form__autosuggest-wrapper {
  margin-bottom: -5px;

  .autosuggest-textarea label {
    display: block !important;
  }
}

a.search-results__hashtag,
a.load-more,
a.mention {
  text-decoration: none !important;
}

.drawer__tab,
a.column-link:link {
  text-decoration: none;
}

.status .status__display-name {
  text-decoration: none;
  margin-bottom: 8px;
}

.compose-form__publish-button-wrapper button {
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 70%);
  background-color: #6a5687;
  border-color: #6a5687;
  text-decoration: none !important;
}

.spoiler-input label,
.search label {
  display: block;
}

.app-holder {
  height: calc(100% - 42px);
}

.modal-root__overlay {
  background: rgba(0, 0, 0, 70%);
}

.admin-wrapper {
  .sidebar-wrapper,
  .content-wrapper {
    background-color: #eee;
    overflow: auto;
    padding-bottom: 42px;

    .sidebar > ul > li > a.selected {
      background-color: #b3cc66;
    }

    .sidebar ul li {
      a {
        color: #666;
      }

      ul li a.selected {
        color: #fff;
      }
    }
  }

  .simple_form span.hint {
    color: #666;
  }
}

.column {
  flex-grow: 1;
}

.fa-star::before {
  content: '\f006';
}

.active .fa-star::before {
  content: '\f005';
}

.public-layout .public-account-header__bar .avatar {
  padding-left: 0;
  margin-left: 16px;
}

.column-header.active .column-header__icon {
  color: #ffc529;
}

.reply-indicator {
  background: #b49cd9;
}

.container.ombre {
  h1 {
    font-size: 36px;
  }

  p {
    margin: 0 0 10px !important;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
  }

  b {
    font-weight: 700;
  }
}

.simple_form {
  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'],
  textarea {
    background: lighten($ui-base-color, 10%);
  }

  select {
    background: lighten($ui-base-color, 10%)
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(lighten($ui-base-color, 12%))}'/></svg>")
      no-repeat right 8px center / auto 16px;
  }

  .check_boxes {
    .checkbox {
      label {
        font-family: inherit;
        font-size: 14px;
        color: $primary-text-color;
        display: block;
        width: auto;
        position: relative;
        padding-top: 5px;
        padding-left: 25px;
        flex: 1 1 auto;
      }

      input[type='checkbox'] {
        position: absolute;
        left: 0;
        top: 5px;
        margin: 0;
      }
    }
  }
}

.checkbox input[type='checkbox'] {
  margin-left: 0 !important;
}

div.introduction {
  height: calc(100% + 42px);
}

.item-list,
.detailed-status__wrapper,
.story,
.explore__suggestions,
.explore__links,
.getting-started,
.drawer__header,
.status__wrapper {
  background: $white;
}

.announcements__item {
  color: $primary-text-color;
}
