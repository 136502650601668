// Dependent colors
$black: #333;
$white: #ffffff;

$classic-base-color: #6A5687;
$classic-primary-color: #B49CD9;
$classic-secondary-color: #EDE6F5;
$classic-highlight-color: #677835;

// Differences
$success-green: #677835;

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: #B49CD9;
$ui-primary-color: #8157C2;
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: #677835;

$primary-text-color: $black !default;
$darker-text-color: $classic-base-color !default;
$dark-text-color: #444b5d;
$action-button-color: #606984;

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: #444b5d;

//Newly added colors
$account-background-color: $white !default;

//Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) + $amount);
}

@function lighten($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) - $amount);
}
